import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import logo from "../../images/logo.jpg";

export const Contacts = () => {
  return (
    <div className="page-wrapper contacts-page">
      <div className="main-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="page-content">
        <h3>Contatti:</h3>
        <p>Siamo sempre a disposizione; potete scriverci o chiamarci:</p>

        <div className="buttons">
          <div>Amministrazione / informazioni:</div>
          <div className="row">
            <a href="mailto:info@alpitec.com" className="highlight-button">
              <FontAwesomeIcon className="button-icon" icon={faEnvelope} />
              <div className="button-description">info@alpitec.com</div>
            </a>
            <a href="tel:00390171261856" className="active-button">
              <FontAwesomeIcon className="button-icon" icon={faPhone} />
              <div className="button-description">+39 0171 261856</div>
            </a>
          </div>

          <div>Supporto tecnico:</div>
          <a href="mailto:tecnico@alpitec.com" className="highlight-button">
            <FontAwesomeIcon className="button-icon" icon={faEnvelope} />
            <div className="button-description">tecnico@alpitec.com</div>
          </a>
        </div>
      </div>
    </div>
  );
};
