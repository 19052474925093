import React, { useEffect } from "react";

import logo from "../../images/logo.jpg";

export const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper home-page">
      <div className="main-logo">
        <img src={logo} alt="alpitec logo" />
      </div>
      <div className="page-content">
        <h3>Privacy policy</h3>
        <p>
          Il nosto sito non utilizza cookies!
          <br />
          Non scarichiamo sul tuo dispositivo alcun tracciante, né condividiamo
          informazioni riguardo la tua navigazione.
        </p>
        <p>
          La sola informazione che questo sito salva nel localStorage (memoria
          del browser) è la tua preferenza riguardo al tema del sito stesso
          (chiaro o scuro).
        </p>
      </div>
    </div>
  );
};
