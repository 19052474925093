import React, { useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import logo from "../../images/logo.jpg";
import progettazione from "../../images/home/progettazione.jpg";
import installazione from "../../images/home/installazione.jpg";

export const Home = () => {
  const contentRef = useRef(null);

  const observerCallback = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      contentRef.current.classList.add("active");
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "-150px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    const target = contentRef.current;

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <div className="page-wrapper home-page">
      <div className="home-images">
        <div className="main-logo">
          <img src={logo} alt="alpitec logo" />
        </div>
        <img
          className="home-image image-design"
          src={progettazione}
          alt="alpitec progettazione"
        />
        <img
          className="home-image image-installation"
          src={installazione}
          alt="alpitec installazione"
        />
      </div>

      <div ref={contentRef} className="page-content reveal">
        <div className="page-title">
          <div className="left-part">PROGETTAZIONE</div>
          <div className="right-part">DIREZIONE LAVORI</div>
        </div>

        <p>
          Alpitec, grazie allo studio di ingegneria interno, è abilitata
          all'esecuzione dei progetti ai sensi del D.M. n. 37/08 (ex Legge n.
          46/90 ? : contentRef.current.classList.remove("active");). Quando sia
          necessario redigere un progetto, i nostri tecnici (ingegneri e periti
          industriali elettrotecnici) producono la documentazione preliminare,
          definitiva ed esecutiva in accordo con le prescrizioni CEI 0-2.
          Particolare attenzione viene rivolta al settore degli Impianti
          Fotovoltaici per i quali, oltre alla progettazione esecutiva, vengono
          eseguite tutte le pratiche documentali ENEL - GSE necessarie per
          l'ottenimento degli incentivi (es. V Conto Energia). Il progetto si
          sviluppa seguendo procedure rigorose, in accordo con quanto richiesto
          dal Sistema di Qualità UNI-EN ISO 9001:2000.
        </p>
        <p>
          L'attività dello studio di progettazione si affianca e supporta
          l'attività di installazione degli impianti. Grazie a questo
          monitoraggio continuo, durante le varie fasi di realizzazione,
          possiamo garantire un risultato finale soddisfacente sotto ogni
          aspetto. L'esecuzione di prove e misure strumentali effettuate sugli
          impianti realizzati, in accordo con quanto richiesto dalle normative
          vigenti, completano l'intera procedura e costituiscono per il
          Committente ulteriore garanzia di sicurezza ed affidabilità.
        </p>
      </div>
    </div>
  );
};
