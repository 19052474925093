import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeContext } from "./ui/contexts/themeContext";

import "./styles/app.scss";

import { MainLayout } from "./ui/layouts/MainLayout";
import { Navbar } from "./ui/navigation/Navbar";
import { Footer } from "./ui/util/Footer";

import { Home } from "./ui/pages/Home";
import { Contacts } from "./ui/pages/Contacts";
import { AboutUs } from "./ui/pages/AboutUs";
import { Works } from "./ui/pages/Works";

import { Privacy } from "./ui/pages/Privacy";

const navbarItems = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Chi siamo",
    path: "/chi-siamo",
  },
  {
    title: "Cosa facciamo",
    path: "/cosa-facciamo",
  },
  {
    title: "Contatti",
    path: "/contatti",
  },
];

export const App = () => {
  const [theme, setTheme] = useState("light");
  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };
  const themeContextValue = {
    theme,
    switchTheme,
  };

  useEffect(() => {
    // restore previously saved theme choice
    if (localStorage.getItem("theme")) {
      setTheme(localStorage.getItem("theme"));
    }
  }, []);

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <BrowserRouter>
        <Navbar items={navbarItems} />

        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="chi-siamo" element={<AboutUs />} />
            <Route path="cosa-facciamo" element={<Works />} />
            <Route path="contatti" element={<Contacts />} />

            <Route path="privacy-policy" element={<Privacy />} />
          </Route>
        </Routes>

        <Footer />
      </BrowserRouter>
    </ThemeContext.Provider>
  );
};
