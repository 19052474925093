import React, { useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faServer,
  faLandMineOn,
  faTv,
  faSun,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../../images/logo.jpg";
import data1 from "../../images/works/data-1.jpg";
import light1 from "../../images/works/light-1.jpg";
import tv1 from "../../images/works/tv-1.jpg";
import solar1 from "../../images/works/solar-1.jpg";

export const Works = () => {
  const cabinRef = useRef(null);
  const alarmRef = useRef(null);
  const tvRef = useRef(null);
  const solarRef = useRef(null);

  const scrollTo = (ref) => ref.current.scrollIntoView();

  return (
    <div className="page-wrapper home-page">
      <div className="main-logo">
        <img src={logo} alt="alpitec logo" />
      </div>
      <div className="page-content">
        <p>La nostra azienda può assistervi in diversi settori, tra i quali:</p>

        <div className="page-navbar">
          <div
            className="page-navbar-item"
            onClick={() => {
              scrollTo(cabinRef);
            }}
          >
            <FontAwesomeIcon className="page-navbar-icon" icon={faServer} />
            Quadristica e cabine di trasformazione mt/bt
          </div>
          <div
            className="page-navbar-item"
            onClick={() => {
              scrollTo(alarmRef);
            }}
          >
            <FontAwesomeIcon className="page-navbar-icon" icon={faLandMineOn} />
            Antintrusione, tvcc e antincendio
          </div>
          <div
            className="page-navbar-item"
            onClick={() => {
              scrollTo(tvRef);
            }}
          >
            <FontAwesomeIcon className="page-navbar-icon" icon={faTv} />
            Tv, telefonia e trasmissione dati
          </div>
          <div
            className="page-navbar-item"
            onClick={() => {
              scrollTo(solarRef);
            }}
          >
            <FontAwesomeIcon className="page-navbar-icon" icon={faSun} />
            Fotovoltaico ed energie alternative
          </div>
        </div>

        <div ref={cabinRef} className="section">
          <div className="page-title">
            <div className="left-part">QUADRISTICA CABINE</div>
            <div className="right-part">TRASFORMAZIONE MT/BT</div>
          </div>

          <img
            className="item-image"
            src={data1}
            alt="Quadristica e cabine di trasformazione mt/bt"
          />

          <p>
            All'interno dell'azienda è presente un'officina destinata
            all'attività di cablaggio per quadri elettrici di distribuzione,
            comando ed automazione.
          </p>
        </div>

        <div ref={alarmRef} className="section">
          <div className="page-title">
            <div className="left-part">ANTINTRUSIONE</div>
            <div className="right-part">TVCC ANTINCENDIO</div>
          </div>

          <img
            className="item-image"
            src={light1}
            alt="Antintrusione, tvcc antincendio"
          />

          <p>
            Gli impianti di antifurto o anti-intrusione possono essere
            realizzati con sensori collegati via cavo alla centrale, oppure
            dotati di trasmissione wireless. La tipologia dei sensori è scelta
            in base alla loro specifica funzione, (magnetici per porte o
            finestre, a doppia tecnologia (doppler e infrarossi passivi) per la
            protezione di spazi chiusi, barriere ottiche per la protezione per
            aree esterne). <br /> Tutti gli impianti sono gestiti da una
            centrale elettronica che assolve a tutte le funzioni, anche alla
            comunicazione a distanza tramite telefono fisso o mobile per
            avvisare le persone addette di una avvenuta intrusione. Inoltre
            detti impianti possono essere gestiti da remoto mediante il
            collegamento a computer.
          </p>
          <p>
            Gli impianti di TVCC sono usati per sorvegliare aree che devono
            essere controllate, sia in ambito civile, (ville, parchi , alberghi)
            che in ambito industriale (capannoni, parcheggi, strutture
            produttive ecc.).
            <br /> Tali impianti sono realizzati con telecamere normali o
            infrarosso, monitor, videoregistratori, sistemi di commutazione
            ciclica, sistemi di trasmissione AV.
          </p>
          <p>
            In base alle prescrizione dei V.F. dove necessario realizziamo
            impianti per la rivelazione di incendio o fughe di gas, utilizzando
            i MATERIALI PIÙ AFFIDABILI PRESENTI SUL MERCATO. Essi sono
            normalmente formati da elementi in campo (sensori – pulsanti –
            suonerie, condotte ecc.) collegati ad una centrale che ne controlla
            e visualizza lo stato.
            <br /> Anche per questa tipologia di lavori siamo in grado di
            fornire un SERVIZIO COMPLETO, partendo dalla PROGETTAZIONE, al
            preventivo di costi, alla REALIZZAZIONE finale con la documentazione
            richiesta dalla normativa attuale.
          </p>
        </div>

        <div ref={tvRef} className="section">
          <div className="page-title">
            <div className="left-part">TV TELEFONIA</div>
            <div className="right-part">TRASMISSIONE DATI</div>
          </div>

          <img
            className="item-image"
            src={tv1}
            alt="Tv, telefonia e trasmissione dati"
          />

          <p>
            Gli impianti di ricezione TV sono ormai presenti in ogni fabbricato
            qualsiasi sia la sua funzione, abitazione privata, alberghiera,
            residenziale o terziario.Essi sono realizzati sia pe la ricezione in
            digitale terrestre, che satellitare, utilizzando le migliori
            apparecchiature presenti sul mercato, supportate da idonea
            strumentazione per la misurazione dei segnali e la centratura delle
            antenne paraboliche.
          </p>
          <p>
            Sono impianti che consentono mediante un cablaggio strutturato, di
            gestire un grande quantità di utenze periferiche, tra loro
            interconnesse sia per la fonia (comunicazione a voce) sia per i dati
            (collegamenti tramite computer che sono tra loro interconnessi e
            possono scambiarsi i vari file). <br />
            Anche questi impianti necessitano di una conformità che attesti la
            rispondenza alle normative, che viene rilasciata mediante il
            collaudo con apposita strumentazione che verifica e stampa i dati
            rilevati su ogni singola presa di utenza.
          </p>
        </div>

        <div ref={solarRef} className="section">
          <div className="page-title">
            <div className="left-part">FOTOVOLTAICO</div>
            <div className="right-part">ENERGIE ALTERNATIVE</div>
          </div>

          <img
            className="item-image"
            src={solar1}
            alt="Fotovoltaico ed energie alternative"
          />

          <p>
            Gli impianti fotovoltaici sono costituiti essenzialmente da un
            assemblaggio di pannelli che sfruttano l’energia solare per produrre
            energia elettrica. Essi possono essere sviluppati in diversi modi a
            seconda della loro ubicazione e del risultato che si vuole ottenere,
            potendo scegliere tra lo “Scambio sul posto“ o il “ritiro dedicato”
            con vendita diretta. A richiesta del cliente siamo in grado di
            fornire i chiarimenti necessari e procedere prima della
            realizzazioneapredisporre il progetto tecnico ed il supporto
            burocratico per il completamento delle pratiche necessariesia
            EnelcheGSE.
          </p>
        </div>
      </div>
    </div>
  );
};
