import React from "react";
import { Outlet } from "react-router-dom";

import { ThemeContext } from "../contexts/themeContext";

export const MainLayout = () => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <div className={`main-layout ${theme}`}>
          <Outlet />
        </div>
      )}
    </ThemeContext.Consumer>
  );
};
