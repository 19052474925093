import React from "react";

import logo from "../../images/logo.jpg";

export const AboutUs = () => {
  return (
    <div className="page-wrapper home-page">
      <div className="main-logo">
        <img src={logo} alt="alpitec logo" />
      </div>
      <div className="page-content">
        <h3>ALPITEC, CON VOI DA OLTRE 40 ANNI !</h3>
        <p>
          La società viene fondata a Cuneo verso la metà degli anni '70 da
          Gianni Emilio Sciolla in affiancamento all'attività preesistente di
          fornitura di materiali elettrici radio e Tv iniziata da Giovanni
          Sciolla negli anni '60. L'intento è quello di operare nel settore
          impiantistico elettrico di tipo civile con indirizzo prevalentemente
          residenziale; la prima denominazione della società è "Sciolla Impianti
          Elettrici".
        </p>
        <p>
          Con l'avvento degli anni '80 l'attività della ditta si sviluppa e
          seguendo costantemente l'evoluzione del mercato si orienta anche verso
          il settore terziario ed industriale. Si aprono nuove prospettive per
          quanto riguarda la clientela che dai precedenti soggetti privati si
          amplia così ai soggetti pubblici quali enti, comuni, provincie. Verso
          la fine degli anni '80 la società, ormai consolidata, esegue già
          installazioni complete a partire dalla fornitura in media tensione
          fino alle utenze finali in bassa tensione realizzando tutte le
          tipologie impiantistiche che si possono presentare all'interno degli
          edifici. E' qui che la denominazione della società si trasforma in
          "Elettroimpianti Sciolla". L'espererienza maturata in azienda dalle
          persone che vi lavorano aumenta considerevolmente e con essa le
          richieste di lavoro, tanto che si rende necessario il trasferimento in
          una nuova sede per far fronte alle esigenze del magazzino materiali,
          degli uffici tecnici, della segreteria. La società si trasferisce
          quindi da Cuneo a Borgo San Dalmazzo, in via Fontana. Nell'intento di
          fornire al cliente un servizio sempre più completo l'azienda si
          specializza anche nelle attività di progettazione ed installazione di
          impianti speciali quali rivelazione incendi, antintrusione,
          videocontrollo, trasmissione dati, automazione e supervisione edifici.
          Per siglare questo ulteriore passo la società si trasforma da
          "Elettroimpianti Sciolla" in "AlpiTec".
        </p>
        <p>
          L'attenzione costante verso le nuove tecnologie ed opportunità di
          sviluppo ha portato in questi ultimi anni la società ad avvicinarsi al
          settore delle energie rinnovabili, con particolare riguardo e
          specializzazione per quanto concerne gli impianti fotovoltaici.
        </p>
      </div>
    </div>
  );
};
