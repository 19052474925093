import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

import { ThemeContext } from "../contexts/themeContext";

export const Navbar = ({ items }) => {
  const [mobileMenuWrapperClass, setMobileMenuWrapperClass] = useState("");

  return (
    <ThemeContext.Consumer>
      {({ theme, switchTheme }) => {
        const themeIcon = theme === "light" ? faMoon : faSun;

        const toggleMobileMenuVisibility = () => {
          mobileMenuWrapperClass === "" ? showMobileMenu() : hideMobileMenu();
        };
        const showMobileMenu = () => {
          setMobileMenuWrapperClass("show");
        };
        const hideMobileMenu = () => {
          setMobileMenuWrapperClass("");
        };

        return (
          <nav className={`main-navbar ${theme}`}>
            <div onClick={toggleMobileMenuVisibility} className="mobile-menu">
              <FontAwesomeIcon icon={faBars} />
            </div>

            <div
              onClick={toggleMobileMenuVisibility}
              className={`mobile-menu-overlay ${mobileMenuWrapperClass}`}
            ></div>

            <div className={`navbar-wrapper ${mobileMenuWrapperClass}`}>
              <div className="main-navbar-items">
                {items.map((item) => (
                  <NavLink
                    to={item.path}
                    className="main-navbar-item"
                    key={item.path}
                    onClick={hideMobileMenu}
                  >
                    <div>{item.title}</div>
                  </NavLink>
                ))}
              </div>

              <div className="main-navbar-actions">
                <div className="main-navbar-theme" onClick={switchTheme}>
                  <FontAwesomeIcon className="button-icon" icon={themeIcon} />
                  <div>Tema</div>
                </div>
              </div>
            </div>
          </nav>
        );
      }}
    </ThemeContext.Consumer>
  );
};
