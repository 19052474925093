import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faUserSecret } from "@fortawesome/free-solid-svg-icons";

import { ThemeContext } from "../contexts/themeContext";

import logo from "../../images/logo.jpg";

export const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <ThemeContext.Consumer>
      {({ theme }) => {
        return (
          <div className={`footer ${theme}`}>
            <div className="footer-header">
              <div className="footer-header-title">
                <FontAwesomeIcon
                  className="footer-scroll-top"
                  icon={faAngleUp}
                  onClick={scrollTop}
                />
                <div className="footer-header-title-text">
                  Copyright Alpitec 2022
                </div>
              </div>
              <NavLink to="privacy-policy" className="footer-header-links">
                <FontAwesomeIcon
                  className="footer-privacy-icon"
                  icon={faUserSecret}
                  onClick={scrollTop}
                />
                Privacy policy
              </NavLink>
            </div>
            <div className="footer-body">
              <div className="footer-logo">
                <img src={logo} alt="alpitec logo" />
              </div>
              <div className="footer-data">
                <div className="footer-address">
                  <a href="mailto:info@alpitec.com">info@alpitec.com</a>
                  <br />
                  Tel. <a href="tel:00390171261856">+39 0171 261856</a> - Fax
                  +39 0171 261856
                  <br />
                  Via XI Settembre, 63 - 12011 Borgo San Dalmazzo (Cn)
                  <br />
                  P.IVA: 00293480042
                </div>
                <div className="footer-company-data">
                  Registro imprese: CN-078-2851 - REA: 97383 - Albo Imprese
                  Artigiane: 51545
                  <br />
                  Certificazione SOA: Artigiansoa n. 43/01 - OS30 - III
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};
